.Partners_section {
  padding-top: 10px;
  background-color: black;
  color: white;
  padding-left: 90px;
  min-height: 500px;
  text-align: start;
}
.Partner_Upload_sec {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;

  padding-bottom: 10px;
}
.Partner_Upload_selector {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0 15px 0;
  gap: 10px;
}
.Selected {
  padding: 5px;
  background-color: rgb(63, 208, 65);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 90px;
  transform: 0.5s ease;
}
.NotSelected {
  background-color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 90px;
}
@media only screen and (max-width: 900px) {
  .Partners_section {
    padding-top: 80px;
    padding-left: 10px;
  }
  .Partners_section > h2 {
    font-size: 18px;
    margin: 0;
  }
}
@media only screen and (max-width: 900px) {
  .NotSelected {
    font-size: 12px;
    width: 80px;
  }
  .Selected {
    padding: 3px;

    width: 80px;
  }
}
