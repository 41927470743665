.Food_contant {
  width: 100%;
  /* margin-top: 90px; */
  padding-top: 10px;
  font-family: "Poppins", sans-serif;
  height: auto;
  background-color: black;
  transition: all 0.5s ease;
}

.Food_contant_item {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  justify-content: space-evenly;
  align-items: center;
  margin-left: 65px;
  padding-bottom: 20px;
  padding-left: 50px;
}
.Food_contant_items {
  width: 300px;
  height: 350px;
  background-color: rgb(211, 208, 208);
  cursor: pointer;
  color: rgb(0, 0, 0);
  padding: 5px;
  border-radius: 5px;
  box-shadow: 2px 5px 5px gray;
}
.Food_contant_item_details {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 60;
  background: rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  backdrop-filter: blur(15px);
  padding-left: 38%;
  padding-top: 50px;
  transition: 0.3s ease;
}
.Food_contant_item_detail::-webkit-scrollbar {
  display: none;
}
.Food_contant_item_details > div > div > div > table > tbody > tr > td {
  font-size: 15px;
  max-width: 130px;
}
.Food_contant_item_detail {
  position: fixed;
  width: 360px;
  height: fit-content;
  min-height: 300px;
  z-index: 61;
  background-color: white;
  border-radius: 30px;
  color: white;
  display: block;
  background-color: #333;
  overflow-y: scroll;
  scroll-behavior: smooth;
  padding-top: 5px;
  top: 15%;
  left: 40%;
  transition: 0.5s ease;
}
.Food_contant_item_detail > button {
  position: absolute;
  right: 10px;
  top: 10px;
  border-radius: 5px;
  cursor: pointer;
  background-color: unset;
  border: none;
}
.Food_contant_item_detail > button > svg {
  color: rgba(6, 132, 210, 0.88);
  font-size: 25px;
}
.Food_contant_Order_btn {
  font-size: 10px;
}
.Food_contant_items > a {
  text-decoration: unset;
}
.Menu_table {
  margin: auto;
  position: relative;
  overflow-y: scroll;
}

.Menu_table > tbody {
  background-color: #333;
  transition: 0.5s ease;
  border-radius: 5px;
  padding: 5px;
}
.foodimg {
  height: 80px;
  width: 100px;
  border-radius: 5px;
}
#Food_contant_title {
  color: white;
  padding: 5px;
  background-image: linear-gradient(
    to right,
    rgba(128, 128, 128, 0.774),
    rgba(128, 128, 128, 0)
  );
  border-radius: 0 0 5px 5px;
  width: fit-content;
  margin-left: 100px;
}
.Food_contant_Item_title {
  color: rgb(0, 0, 0);
  height: 60px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 270px;
  font-size: 20px;
}
.Food_contant_img {
  height: 200px;
  min-height: 200px;
  min-width: 250px;
  width: 250px;
  border-radius: 10px;
  border: 2px solid grey;
  margin: 5px;
  margin-left: 15px;
  background-image: url("../assets/loading.svg");
  background-repeat: no-repeat;
  background-position: center;
}
.Food_Contant_empty {
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Food_Hotel_Menu_Btn {
  border: none;
  background-color: rgba(6, 132, 210, 0.88);
  border-radius: 5px;
  color: white;
  cursor: pointer;
  display: grid;
  align-items: center;
  justify-content: center;
  width: 100px;
}
.Food_Hotel_Menu_Btn:hover {
  background-color: greenyellow;
  color: black;
  transition: 0.3s;
}
.Food_contant_img:hover {
  transition: 150ms;
  transform: scale(1.1);
}
.Food_contant_about {
  color: white;
}
.Food_hotel_rating {
  margin: 1px;
  display: flex;
  justify-content: center;
  gap: 20px;
}
.Food_hotel_rating > div > svg {
  color: gold;
  filter: drop-shadow(0 0 5px black);
}
.Food_hotel_rating > span > a > svg {
  color: black;
  height: 25px;
  width: 25px;

  padding: 1px;
  margin-left: 5px;
}
.Food_hotel_rating > span > a {
  background-color: rgb(30, 255, 0);
}
.Food_hotel_rating > span:hover {
  transform: scale(1.1);
  transition: 0.3s;
}
@media only screen and (max-width: 900px) {
  .Food_contant {
    padding-top: 55px;
  }
  #Food_contant_title {
    font-size: 8px;
    font-size: 20px;
    margin-left: 20px;
    padding-top: 10px;
  }
  .Food_contant_item {
    margin-left: 0;
    padding-bottom: 10px;
    gap: 10px;
    padding-left: 15px;
  }
  .Food_contant_items {
    width: 300px;
  }
  .Food_contant_item {
    gap: 15px;
  }
  .Food_contant_item_detail {
    left: 25%;
  }
}
@media only screen and (max-width: 450px) {
  .Food_contant_img {
    width: 250px;
    height: 200px;
  }
  .Food_contant_item_detail {
    left: 25px;
    width: 340px;
  }
}
