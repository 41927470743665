.Myitem_Contant {
  padding-top: 20px;
  color: white;
  min-height: 700px;
  transition: 0.5s ease;
  min-height: 600px;
  padding-left: 80px;
}
.Myitem_Item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  padding-bottom: 20px;
}
.Myitem_Item_Empty {
  padding-top: 50px;
}
.Myitem_Item_list {
  background-color: #333;
  padding: 5px;
  height: fit-content;
  width: 300px;
  border-radius: 10px;
}
.Myitem_Item_Titel{
    margin: 5px;
}
.Myitem_Item_img {
  height: 200px;
  width: 260px;
  border-radius: 10px;
}
.Myitem_Item_Details {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.Myitem_Item_Details > :last-child {
  cursor: pointer;
}
.Myitem_Bookings_Items{
  text-align: start;
}
@media only screen and (max-width: 900px) {
  .Myitem_Contant {
    padding-top: 50px;
    transition: 0.5s ease;
    padding-left: 20px;
  }
}
@media only screen and (max-width: 480px) {
  .Myitem_Contant {
    padding-left: 10px;
  }
 .Myitem_Item_list{
  height: fit-content;
  width: 380px;
  position: relative;
  transition: 0.3s ease;
 }
 .Myitem_Item_img{
  height: 60px;
  width: 60px;
  position: absolute;
  top: 10px;
  left: 5px;
 }
 .Myitem_Bookings_Items>p{
  margin: 2px;
 }
}
