.Mybooking {
  height: fit-content;
  background-color: black;
  color: white;
  padding: 15px;
}
.Mybooking_contant_Vehicles {
  width: 280px;
  background-color: rgb(211, 208, 208);
  border-radius: 5px;
  padding: 5px;
  color: black;
}
.Mybooking_contant_Vehicles > button > svg {
  cursor: pointer;
}

.Mybooking_title {
  padding: 5px;
  border-radius: 5px 0 0 5px;
  background-image: linear-gradient(
    to right,
    rgba(128, 128, 128, 0.774),
    rgba(128, 128, 128, 0)
  );
}
.Mybooking > h1 {
  margin: 0;
  border-radius: 0 0 0 5px;
}

.Delete_Hotel {
  border: none;
  outline: none;
  background-color: red;
  color: rgb(255, 255, 255);

  border-radius: 5px;
  cursor: pointer;
}
.Mybooking_Btns{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.Delete_Hotel_Res {
  border: none;
  outline: none;
  background-color: red;
  color: rgb(255, 255, 255);
  margin-left: 90px;
  border-radius: 5px;
  cursor: pointer;
}

.Delete_Hotel:hover {
  filter: drop-shadow(0 0 10px red);
  transition: 0.3s;
}
.MyAssets {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-top: 5px;
}
.MyAssets_Type {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 15px;
}
.Mybooking_Btn_Date{
  border: unset;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s ease;
}
.Mybooking_Btn_Date:hover{
  background-color: #004080;
  color: white;
}
.Mybooking_Add_Title {
  text-align: center;
  margin: 0;
  margin-top: 2px;
  display: flex;
  gap: 10px;align-items: center;
  justify-content: center;
}
/* DatePickerStyles.css */

/* Container styling */
.react-datepicker {
  font-family: "Arial", sans-serif;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 50;
}

/* Header styling */
.react-datepicker__header {
  background-color: #004080;
  color: white;
  border-bottom: 1px solid #ddd;
  border-radius: 8px 8px 0 0;
  padding: 8px 0;
}

/* Day name styling */
.react-datepicker__day-name {
  color: #666;
  font-weight: bold;
  padding: 5px 0;
}

/* Day styling */
.react-datepicker__day {
  color: #333;
  font-weight: normal;
  padding: 10px;
  margin: 5px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.react-datepicker__day:hover {
  background-color: #cce5ff;
  color: #004080;
}

.react-datepicker__day--selected {
  background-color: #004080;
  color: white;
}

/* Navigation button styling */
.react-datepicker__navigation {
  top: 10px;
  border: none;
  background: none;
}

.react-datepicker__navigation-icon::before {
  border-color: #004080;
}

/* Month dropdown styling */
.react-datepicker__month-dropdown-container {
  background-color: #f0f0f0;
  color: #333;
}

/* Year dropdown styling */
.react-datepicker__year-dropdown-container {
  background-color: #f0f0f0;
  color: #333;
}

/* Today button styling */
.react-datepicker__today-button {
  background-color: #004080;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  margin-top: 10px;
}

.react-datepicker__today-button:hover {
  background-color: #003366;
}

.Asset_available {
  position: fixed;
  z-index: 50;
  padding: 10px;
  background-color: black;
  border-radius: 5px;
  margin: 5px;
}
.hotel_date_card_Owner {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10;
  background: rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  backdrop-filter: blur(15px);
  padding-left: 35%;
  padding-top: 50px;
}
.hotel_date_card_Owner_con {
  width: fit-content;
  height: 370px;
  padding: 10px;
  border-radius: 5px;
  background-color: #333;
}
.Menu_cancel {
  transform: translate(495px);
  outline: none;
  border-radius: 10px;
  cursor: pointer;
  position: absolute;
  background-color: unset;
  border: none;
  color: rgb(57, 147, 207);
  font-size: 20px;
}
.Date_cancel {
  transform: translate(270px);
  outline: none;
  border-radius: 10px;
  cursor: pointer;
  position: absolute;
  background-color: unset;
  border: none;
  color: rgb(57, 147, 207);
  font-size: 25px;
}
.hotel_date_card_Owner > div > :nth-child(3) {
  margin: 3px;
}
.Book_Btns {
  border-radius: 5px;
  outline: none;
  border: unset;
  margin-left: 5px;
  cursor: pointer;
}
.Book_Btns:hover {
  background-color: greenyellow;
  transition: 0.3s ease;
}
.rmdp-input {
  color: #333;
  cursor: pointer;
}
.hotel_date_card_Owner > button {
  border-radius: 5px;
}
.MyAsset_Click {
  font-size: 15px;
  border-radius: 5px;
  text-align: center;
  padding: 3px;
}
.MyAsset_Click > span {
  margin-left: 5px;
}
.MyAssets_data_area {
  background-color: #3333332c;
  border-radius: 5px;
  padding: 5px;
}
.MyAssets_data_area > p {
  margin: 1px;
}
.hotel_Menu_card {
  width: fit-content;
  background-color: #333;
  padding: 15px;
  border-radius: 5px;
  transition: all 0.5s ease;
}
.hotel_Menu_input {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-bottom: 10px;
  border-bottom: 1px solid gray;
  margin-bottom: 3px;
}

.hotel_date_card_Owner > div > p {
  margin: 1px;
}
.hotel_date_card_Owner > div > p > svg {
  color: yellow;
}
.hotel_Menu_input > input {
  width: 98px;
  font-size: 15px;
  border-radius: 5px;
}
.hotel_Menu_input > label {
  padding-left: 2px;
}

.hotel_Menu_card > form > button {
  cursor: pointer;
  border-radius: 5px;
}
.Menu_Submit {
  background-color: rgb(30, 255, 0);
  border: unset;
}
.Menu_Submit:hover {
  filter: drop-shadow(0 0 10px rgb(30, 255, 0));
  transition: 0.3s ease;
}
.Menu_Btns {
  background-color: rgba(6, 132, 210, 0.88);
  margin-right: 5px;
  border: unset;
}
@media only screen and (max-width: 900px) {
  .hotel_date_card_Owner {
    padding-left: 20%;
    padding-top: 110px;
  }
  .hotel_Menu_card {
    width: 410px;
  }
  .Menu_cancel {
    transform: translate(330px);
  }
  .hotel_Menu_input > label > input {
    width: 50px;
    border-radius: 5px;
  }
}
@media only screen and (max-width: 470px) {
  .hotel_date_card_Owner {
    padding-left: 30px;
    padding-top: 150px;
  }
  .hotel_Menu_card {
    width: 320px;
    padding-bottom: 30px;
  }
  .hotel_Menu_input {
    justify-content: space-evenly;
  }
  .Menu_cancel {
    transform: translate(260px);
  }
  .hotel_Menu_input > input {
    width: 150px;
    margin: 5px;
  }
  .hotel_Menu_input > label {
    margin: 5px;
  }
}
