.hotelcard {
  position: fixed;
  top: 20px;
  left: 120px;
  right: 20px;
  width: inherit;
  height: 100%;
  z-index: 50;
  background-color: white;
  border-radius: 30px;
  color: black;
  display: block;
  background-color: #333;
  overflow-y: scroll;
  padding-bottom: 120px;
  scroll-behavior: smooth;
}
.hotelcard::-webkit-scrollbar {
  display: none;
}
.Hotel_Loading_Ani {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 600px;
}
.Hotel_Not_Found {
  color: black;
  text-decoration: unset;
}
.Hotel_Not_Found_Btn {
  border-radius: 10px;
  border: unset;
  text-decoration: unset;
  filter: drop-shadow(5px 0px 20px red);
  cursor: pointer;
  transition: 0.3s ease;
}
.Hotel_Not_Found_Btn:hover {
    filter: drop-shadow(5px 0px 20px greenyellow);
    background-color: yellowgreen;
    font-weight: 800px;
}
@media only screen and (max-width: 900px) {
  .hotelcard {
    left: 25px;
    top: 90px;
  }
}

@media only screen and (max-width: 480px) {
  .hotelcard {
    left: 15px;
  }
}
