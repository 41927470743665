.Footer {
  width: 100%;
  height: fit-content;
  background-color: #333;
  margin: 0;
  font-family: "Poppins", sans-serif;
  filter: drop-shadow(0 0 5px white);

}
.Footer_contact_title {
  color: white;
  font-size: 10px;
  display: flex;
  justify-content: center;
  
  margin-bottom: 20px;
}
.Footer_social {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.Footer_icon {
  height: 50px;
  border-radius: 20px;
}
.Footer_icons {
  text-decoration: unset;
  color: white;
}
.Footer_icons:hover {
  cursor: pointer;
  color: rgb(27, 203, 238);
  filter: drop-shadow(0 0 10px rgb(27, 203, 238));
  transform: scale(1.2);
  transition: 0.3s;
}
.Footer_email {
  font-weight: 100;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  
}
.Footer_email > div > h4{
  cursor: pointer;
  text-decoration: none;
  color: white;
  text-decoration-line: none;
}
.Footer_email_title {
  font-size: 10px;
}
.Footer_email_contant{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  justify-content: space-around;
  font-size: 10px;
  gap: 10px;
}
.About_link{
  color: white;
  text-decoration: none;
}
.Emailus_hover:hover{
  color: hsl(190, 95%, 30%);
  filter: drop-shadow(0 0 20px hsl(190, 95%, 30%));
  transition: 0.3s ease;
 
}
.PWA_Btn{
  border: 1px solid white;
  border-radius: 5px;
  padding: 5px;
  font-weight: 700;
  background-color: rgba(128, 128, 128, 0);
  color: white;
  cursor: pointer;
}
@media only screen and (max-width: 900px) {
  .Footer_contact_title {
    font-size: 20px;
    margin: 1px;
  }
  .Footer_email {
    font-size: 10px;
    flex-wrap: wrap;
    gap: 10px;
  }
  .Footer_icon {
    height: 30px;
  }
  .Footer_email_contant{
    justify-content: space-evenly;
    gap: 5px;
    font-size: 10px;
  }
  .Footer_email_title{
    margin: 1px;
  }
  .Footer_email_contant>h4{
    margin: 5px;
  }
  
}
@media only screen and (max-width: 480px) {
  .Footer_email_contant{
    justify-content: start;
    padding-left: 15px;
    gap: 2px;
  }
}
