.Plans {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  padding-left: 20px;
  height: fit-content;
}
.Sub_titel {
  background-image: linear-gradient(
    to right,
    rgba(128, 128, 128, 0.774),
    rgba(128, 128, 128, 0)
  );
  padding: 5px;
  border-radius: 0 0 5px 5px;
}
.Sub_Plans_Name{
  margin: 2px;
}
.Sub_Plan {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}
.Sub_Plans {
  border: 1px solid gray;
  border-radius: 10px;
  padding: 10px;
  width: fit-content;
  margin: 5px;
}
.Price {
  background-color: rgba(65, 236, 151, 0.689);
  width: fit-content;
  transition: 0.5s;
  border-radius: 5px;
  padding: 2px;
  border: 1px solid black;
  color: black;
  font-weight: 700;
}
.Sub_offer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 15px;
}
.Sub_offer{
  font-size: 20px;
  font-weight: 800;
  padding-top: 15px;
}
.Sub_offer > img {
  border-radius: 15px;
}
.Sub_Plans_Pay_btn{
  cursor: pointer;
  border: none;
  border-radius: 5px;
}
.Sub_Plans_Pay_btn:hover{
  background-color: rgb(37, 151, 221);
  color: white;
}
.Plan_Selector:hover {
  transform: scale(1.1);
  filter: drop-shadow(5px 5px 20px rgba(114, 177, 216, 0.405));
  .Price {
    filter: drop-shadow(10px 10px 20px rgba(65, 236, 151, 0.689));
  }
}
.Plan_Selector {
  display: block;
  height: fit-content;
  width: 230px;
  margin-bottom: 30px;
  border: 2px solid rgba(114, 177, 216, 0.405);
  border-radius: 5px;
  padding: 10px;
  transition: 0.3s;
}
.Plan_select {
  height: 20px;
  width: 20px;
  background-color: greenyellow;
  cursor: pointer;
}
.Sub_Btn {
  border: none;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  text-align: center;
  margin-left: 20px;
}
.Sub_Btn > a {
  text-decoration: unset;
  color: black;
}
.Sub_Btn_non {
  display: none;
}
.Payment_Email {
  margin: 0;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}
.Payment_Email > a > span > svg {
  color: green;
  font-size: 20px;
}
.Payment_Email > a > span > svg:hover {
  filter: drop-shadow(0 0 20px rgb(0, 255, 0));
}
.Sub_Assistance_Btn{
  border-color: yellowgreen;
  border: unset;
  border-radius: 10px;
  cursor: pointer;
  }
  .Sub_Assistance_Btn>svg{
   height: 25px;
   width: 25px;
  }
  .Sub_offer_Time>p{
    margin: 0;
  }
  .Payment_Email>img{
    height: 200px;
    width: 300px;
    margin: 10px;
    border-radius: 10px;
  }
@media only screen and (max-width: 900px) {
  .Plans {
    padding-left: 30px;
    padding-bottom: 10px;
  }
}
@media only screen and (max-width: 480px) {
  .Sub_offer > img {
    height: 80px;
    width: auto;
  }
  .Sub_offer_Time{
    font-size: 15px;
  }
  .Sub_offer_Time>p{
    margin: 0;
  }
}
