.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App_Chat_Btn {
  position: fixed;
  bottom: 40px;
  right: 30px;
  z-index: 100;
}

.App_Chat_Btn_no {
  background-color: rgba(96, 225, 61, 0.593);
  border: none;
  padding: 5px;
  border-radius: 50px;
  cursor: pointer;
  transition: 0.3s ease;
}
.App_Chat_Btn_View {
  background-color: rgb(96, 225, 61);
  border-radius: 50px;
  padding: 5px;
  border: none;
  transition: 0.3s ease;
  cursor: pointer;
  position: relative;
  height: 65px;
  width: 65px;
}
.Chat_Animation {
  border-radius: 50px;
  border-top: 5px solid red;
  border-right: 5px solid red;
  padding: 6px;
  animation: chatani_Back infinite 1.5s linear;
  position: absolute;
  height: 70px;
  width: 70px;
  top: -2px;
  left: -2px;
}
@keyframes chatani_Back {
  0% {
    transform: rotate(0deg);
    
  }
  100% {
    transform: rotate(360deg);
  }
}
.App_Chat_Btn > span > a > button:hover {
  background-color: rgb(96, 225, 61);
  transform: scale(1.1);
}
.App_Chat_Btn > span > a > button > svg {
  height: 40px;
  width: 55px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
