.Upload_hotel_form {
  max-width: 450px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
  min-height: 455px;
  max-height: fit-content;
  margin-bottom: 20px;
  border-radius: 10px;
  border: 1px solid gray;
  color: white;
  text-align: start;
  transition: 0.5s ease;
}

.Hotel_id_value {
  display: none;
}
.Upload_hotel_form > input {
  transition: 0.5s ease;
  width: 440px;
  border-radius: 10px;
  background-color: rgba(128, 128, 128, 0.187);
  color: white;
  text-indent: 5px;
}
.Upload_hotel_form > span > textarea {
  width: 415px;
  border-radius: 10px;
  background-color: rgba(128, 128, 128, 0.187);
  color: white;
  text-indent: 5px;
  min-height: 70px;
  min-width: 195px;
  max-width: 415px;
  max-height: 400px;
}
.Select_Image{
  height: 70px;
   width: 100px;
  border-radius: 10px;
}
.Selected_Items{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 5px;
}
.Travel_Seat_Icon{
  font-size: 20px;
}
.Travel_Seating_Select{
  width: 150px;
  border-radius: 5px;
  background-color: rgba(128, 128, 128, 0.325);
  color: white;
}
.Travel_Seating_Select > option{
  background-color: rgba(128, 128, 128, 0.14);
  color: white;
  background: black;
}
.Upload_btn {
  background-color: rgb(63, 208, 65);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Upload_btn:hover {
  filter: drop-shadow(0 0 15px rgb(63, 208, 65));
}
.Upload_Info {
  font-size: 10px;
  width: 220px;
}
.Asset_Keyword {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.Asset_Keyword > span > svg {
  cursor: pointer;
}
@media only screen and (max-width: 480px){
  .Upload_hotel_form{
    width: 350px;
    transition: 0.5s ease;
  }
  .Upload_hotel_form > span > textarea {
    width: 300px;
    max-width: 300px;
    transition: 0.5s ease;
  }
} 