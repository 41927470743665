.slide-container{
    z-index: -2;
    background-color: #333;
    color: white;
    /* margin-top: 100px; */
    padding: 10px;
}
.video-container {
    width: 100%;
    position: relative;
    display: flex;
    align-items: start;
    justify-content: space-evenly;
    flex-wrap: wrap;
    height: fit-content;
    gap: 10px;
    align-items: flex-start;
    justify-content: center;
  }
  
  .hover-video {
    width: 50%;
    position: relative; /* Adjust as needed */
    transition: 0.9 ease;
    opacity: 0.9;
    margin: 10px;
    border-radius: 10px;
    cursor: pointer;
  }
  
  .hover-video:hover {
    opacity: 1; /* Dim the video on hover */
    transform: scale(1.05);
    transition: 1s;
  }

  .slide-container_titel{
    position: absolute;
    top: 20px;
    left: 70px;
    background-image: linear-gradient(to right, rgba(128, 128, 128, 0.774), rgba(128, 128, 128, 0));
    padding:10px;
    border-radius: 5px;
  }
  .slide-container>p{
 
    font-size: 20px;
    text-indent: 20px;
  }
  .slide-container_titel_contant{
    text-indent: 20px;
    margin: 30px 30px 0px 100px;
    
  }
  .slide-container_btn{
    background-color: rgba(6, 132, 210, 0.88);
    color: white;
    border: none;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
    margin: 5px;
    outline: none;
    min-width: 80px;

  }
  .slide-container_btn:hover{
    background-color: rgb(27, 249, 175);
    color: black;
    transition: ease-in 0.5s;

  }
  .Slide_container_img_slide{
    z-index:-2;
    margin: 80px;
    border-radius: 5px;
    
  }
  @media only screen and (max-width :900px){
    .slide-container{
      padding-top: 80px;
    }
    .hover-video{
      width: 350px;
    }
    .slide-container_titel{
      top: 2px;
      padding: 2px;
      font-size: 15px;
      font-weight: 200;
      
    }
    .slide-container_titel_contant{
      margin-left: 10px;
    }
    .slide-container_btn{
      padding: 2px;
      font-size: 15px;
    }
    .slide-container>p{
      font-size: 15px;
      margin-left: 10px;
    }.Slide_container_img_slide{
      margin: 5px;
      border-radius: 10px;
    }
    
  }

