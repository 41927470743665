.Package_Content {
  color: white;
  min-height: 700px;
  transition: 0.5s ease;
  transition: all 0.5s ease;
}
.Package_contant_title {
  color: white;
  padding: 5px;
  background-image: linear-gradient(
    to right,
    rgba(128, 128, 128, 0.774),
    rgba(128, 128, 128, 0)
  );
  border-radius: 0 0 5px 5px;
  width: fit-content;
  margin-left: 90px;
}
.Pakage_pay_Status {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 70;
  border-radius: 5px;
  z-index: 61;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(15px);
  transition: 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Pakage_pay_Status {
  display: none;
}
.Package_Select {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
}
.Package_TopList {
  padding: 10px;
  text-align: start;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding-left: 95px;
  gap: 15px;
}
.Package_TopList_Items {
  border: 1px solid gray;
  border-radius: 10px;
  padding: 10px;
  width: 330px;
  min-height: 560px;
}
.Package_TopList_Items > p {
  background-color: #333;
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
}
.Paid_True {
  display: block;
  background-color: #333;
  height: 200px;
  width: 300px;
  padding: 10px;
  border-radius: 5px;
  padding-top: 40px;
}
.NotPaid_True {
  display: block;
  background-color: #333;
  height: 200px;
  width: 300px;
  padding: 10px;
  border-radius: 5px;
}
.PaidFalse {
  display: none;
}
.NotPaidFalse {
  display: none;
}
.Pakage_pay_Btn {
  background-color: rgb(63, 208, 65);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s ease;
  position: relative;
  transform: translate(210px);
}
.Pakage_pay_Btn:hover {
  filter: drop-shadow(0 0 15px rgb(63, 208, 65));
}
.Package_search_bar {
  width: 400px;
  height: 40px;
  outline: unset;
  border: none;
  border-radius: 5px 0 0 5px;
}
.Package_Search_Btn {
  background-color: #078bc9;
  border: none;
  padding: 2px;
  width: 30px;
  cursor: pointer;
  height: 40px;
  border-radius: 0 5px 5px 0;
  width: 40px;
}
.Package_Filter_Btn {
  background-color: #078bc9;
  border: none;
  padding: 2px;
  width: 30px;
  cursor: pointer;
  height: 40px;
  border-radius: 5px 0px 0px 5px;
  width: 40px;
}
.Package_Go_Btn {
  background-color: rgb(30, 255, 0);
  border: none;
  padding: 2px;
  width: 25px;
  cursor: pointer;
  height: 25px;
  border-radius: 5px;
  width: 40px;
  margin: 8px;
}
.Package_Go_Btn > svg {
  font-size: 20px;
}
.Package_Filter_Section {
  height: fit-content;
  border: 1px solid gray;
  border-radius: 5px;
  margin: 10px 10px 10px 90px;
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;
}
.Package_Filter_Section > form > input {
  width: 170px;
  cursor: pointer;
}
.Filter_Form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  transition: 0.3s ease;
  padding-top: 5px;
  gap: 15px;
}
.Filter_Select {
  width: 100px;
}
.Fliter_Data {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 10px;
  border: 1px solid gray;
  border-radius: 5px;
  margin-left: 90px;
  margin-right: 10px;
}
.Filter_Img {
  height: 150px;
  width: 200px;
  border-radius: 5px;
}
.Filter_Input {
  cursor: pointer;
  width: 200px;
}
.Filter_Input::-webkit-scrollbar-thumb {
  background-color: greenyellow;
}
.Filter_Results {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: center;
  gap: 10px;
}
.Filter_Result {
  display: flex;
  flex-direction: row-reverse;
  align-items: baseline;
  justify-content: center;
  gap: 10px;
}
.Filter_Result_item {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid gray;
  margin: 5px;
  min-height: 200px;
}
.Filter_Result_item > p {
  margin: 1px;
}
.Filter_Result_item > h4 {
  margin: 2px;
}
.Filter_Result_Title {
  background-image: linear-gradient(
    to right,
    rgba(128, 128, 128, 0.774),
    rgba(128, 128, 128, 0)
  );
  border-radius: 5px 0 0 5px;
}
.Filter_Call > svg {
  color: rgb(0, 0, 0);
  margin-left: 15px;
  cursor: pointer;
  background-color: greenyellow;
  border-radius: 5px;
}
.Filter_Call:hover {
  color: greenyellow;
  transition: 0.5s ease;
}
.Beta_Version {
  color: rgb(10, 194, 10);
  padding: 2px;
  margin-right: 3px;
  border-radius: 10px;
  border: 1px solid gray;
  margin-top: 5px;
}
@media only screen and (max-width: 900px) {
  .Package_Content {
    padding-top: 60px;
  }
  .Pakage_pay_Status {
    left: 30%;
  }
  .Package_TopList {
    padding-left: 15px;
  }
  .Package_search_bar {
    width: 300px;
  }
  .Package_contant_title {
    margin-left: 30px;
    font-size: 25px;
  }
  .Package_Filter_Section {
    margin-left: 30px;
    width: fit-content;
  }
  .Fliter_Data {
    margin-left: 30px;
  }
}
@media only screen and (max-width: 480px) {
  .Pakage_pay_Status {
    left: 10%;
  }
  .Package_search_bar {
    width: 260px;
  }
  .Package_contant_title {
    margin-left: 15px;
    font-size: 18px;
  }
  .Package_Filter_Section {
    margin-left: 15px;
    padding-top: 5px;
    width: fit-content;
    border: unset;
  }
  .Filter_Select {
    width: 220px;
  }
  .Filter_Form {
    gap: 10px;
    padding: 5px;
    border: 1px solid grey;
    width: fit-content;
    border-radius: 5px;
  }
  .Filter_Input {
    width: 210px;
  }
  .Fliter_Data {
    margin-left: 15px;
  }
  .Filter_Img {
    height: 100px;
    width: 140px;
  }
  .Filter_Result_item > h4 {
    font-size: 14px;
  }
  .Filter_Result_item > span {
    font-size: 15px;
  }
  .Filter_Result_Title {
    font-size: 18px;
  }
  .Filter_Result_item {
    min-width: 170px;
  }
  .Filter_Call > svg {
    height: 20px;
    width: 20px;
  }
}
