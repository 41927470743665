.Stay_contant {
  padding-top: 5px;
  width: 100%;
  min-height: 700px;
  padding-left: 80px;
  max-height: fit-content;
  background-color: black;
  font-family: "Poppins", sans-serif;
  color: white;
  transition: all 0.5s ease;
}
.Stay_contant_title {
  color: white;
  padding: 5px;
  background-image: linear-gradient(
    to right,
    rgba(128, 128, 128, 0.774),
    rgba(128, 128, 128, 0)
  );
  border-radius: 0 0 5px 5px;
  width: fit-content;
  margin-left: 50px;
}
.Stay_contant_Hotels {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 20px;
  color: white;
  margin-left: 30px;
  text-align: center;
  padding-bottom: 20px;
  padding-top: 10px;
}
.Stay_hotel_title {
  margin-top: 15px;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 250px;
}
.Stay_data {
  margin-left: 90px;
}
.Stay_hotel_img {
  border-radius: 5px;
  height: 200px;
  width: 270px;
  min-height: 200px;
  cursor: pointer;
  background-image: url("../assets/loading.svg");
  background-repeat: no-repeat;
  background-position: center;
}
.Stay_hotel_img:hover {
  transform: scale(1.1);
  transition: 0.3s;
}
.Stay_Amenity {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
  cursor: pointer;
  padding-top: 5px;
}
.Stay_Amenity:hover {
  background-color: rgba(210, 208, 208, 0.563);
  transition: 0.3s;
}
.Stay_Amenity > p {
  margin: 0;
  max-height: 27px;
  text-align: left;
  width: 230px;
  overflow-y: scroll;
}
.Stay_Amenity > p:hover {
  background-color: rgba(128, 128, 128, 0.511);
  transition: 0.3s;
  border-radius: 5px;
}
.Stay_Amenity > svg {
  font-size: 20px;
}

.Stay_Amenity_items > p::-webkit-scrollbar {
  display: none;
}

.Stay_hotel_call {
  background-color: rgb(30, 255, 0);
  padding: 5px;
  border-radius: 5px;
  position: absolute;
  width: 40px;
  padding-bottom: 0;
  cursor: pointer;
  margin: 5px;
  transform: translate(-10px, -40px);
}
.Stay_hotel_call:hover {
  background-color: rgba(6, 132, 210, 0.88);
  filter: drop-shadow(0 0 15px rgba(6, 132, 210, 0.88));
  transition: 0.3s;
  color: white;
  font-weight: 500;
}
.loading {
  animation: loadanimation 2.5s infinite linear;
  height: 90px;
  width: 90px;
  border-radius: 50px;
  padding: 2px;
  background-color: rgb(11, 197, 221);
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading > div {
  height: 80px;
  width: 80px;
  border-radius: 50px 10px 50px 10px;
  display: block;
  background-color: rgb(0, 0, 0);
}
.Stay_Hotels_items {
  background-color: rgb(211, 208, 208);
  border-radius: 5px;
  color: black;
  box-shadow: 2px 5px 5px gray;
  padding: 10px;
  margin: 7px;
  max-width: 320px;
  cursor: pointer;
}
/* Slider */
.slider-container {
  width: 280px;
  height: 210px;
  margin: 5px;
  position: relative;
}

img {
  width: 300px;
  transition: transform 0.3s ease;
}

.Stay_Slide_img {
  width: 100%;
  height: 200px;
  z-index: 2;
  border-radius: 5px;
}
.slick-dots {
  position: absolute;
  bottom: 10px; /* Adjust as needed */
  width: 100%;
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.slick-dots li {
  margin: 0 5px;
}

.slick-dots li button:before {
  font-size: 12px; /* Adjust size */
  color: blue; /* Change color */
}

.slick-dots li.slick-active button:before {
  color: red; /* Change active dot color */
}
.Stay_Hotel_Details_Img {
  height: 250px;
  width: 500px;
  border-radius: 20px;
  padding: 5px;
}
.Review_Hotel_Name {
  display: none;
}
.Stay_Hotel_Display_Imgs {
  width: 100%;
  margin: 30px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  overflow-x: scroll;
  border-radius: 10px;
  scroll-behavior: smooth;
}

.Stay_Hotel_Display_Imgs::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.Stay_Hotel_Display_Imgs::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
  border-radius: 10px;
}
.Stay_Hotel_Display_Imgs::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
.Stay_Hotel_Display_Imgs::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.Load_more_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-bottom: 10px;
}
.Load_more_btn > button {
  border-radius: 10px;
  border: none;
  cursor: pointer;
}
.Load_more_btn > button:hover {
  background-color: greenyellow;
}
.visible {
  display: block;
}
.hidden {
  display: none;
}
.Stay_Filter {
  background-color: rgba(6, 132, 210, 0.88);
  border: none;
  padding: 2px;
  width: 30px;
  position: absolute;
  left: -50px;
  cursor: pointer;
  height: 40px;
  border-radius: 5px 5px 5px 5px;
  width: 40px;
  color: black;
}
.Stay_Filter:hover {
  color: white;
}
.Stay_Hotel_Filter {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  flex-wrap: wrap;
}
.Hotel_Icons_Hover:hover {
  animation: hover_Icons 0.5s ease;
}
@keyframes hover_Icons {
  0% {
  }
  100% {
    color: blue;
    transform: scale(1.5);
  }
}
.card {
  position: fixed;
  top: 90px;
  left: 120px;
  right: 20px;
  width: inherit;
  height: 100%;
  z-index: 50;
  background-color: white;
  border-radius: 30px;
  color: black;
  display: block;
  background-color: #333;
  overflow-y: scroll;
  padding-bottom: 120px;
  scroll-behavior: smooth;
}
.card::-webkit-scrollbar {
  display: none;
}
/* .card::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
  border-radius: 10px;
  
}
.card::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
.card::-webkit-scrollbar-thumb:hover {
  background: #555;
} */
.noCard {
  display: none;
  position: fixed;
  height: 1px;
}
.DisplayHotelCancel {
  text-align: right;
  margin: 10px 30px 0 0;
  transition: 0.3s;
  position: sticky;
  right: 0;
  top: 0;
  z-index: 50;
}
.DisplayHotelCancel > svg {
  font-size: 30px;
  color: rgba(6, 132, 210, 0.88);
}
.DisplayHotelCancel > svg:hover {
  transform: scale(1.1);
  cursor: pointer;
}
.Stay_Hotel_Display {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;
}
.Stay_Hotel_Display_Title {
  color: white;
  margin: 5px;
}
.Stay_Hotel_Display_Price {
  text-align: start;
  color: white;
  margin: 0 0 0 15px;
}
.Stay_Display_call {
  background-color: rgb(30, 255, 0);
  padding: 5px;
  border-radius: 5px;
  position: absolute;
  right: 50px;
  width: 40px;
  padding-bottom: 0;
  cursor: pointer;
  margin: 5px;
  height: 35px;
  transform: translate(20px, -30px);
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s ease;
}
.Post_Ok {
  height: 30px;
  width: 30px;
}
.Stay_Display_Post {
  background-color: #333;
  padding: 5px;
  border-radius: 5px;
  position: absolute;
  right: 50px;
  width: 40px;
  padding-bottom: 0;
  cursor: pointer;
  margin: 5px;
  margin-top: 15px;
  height: 300px;
  width: 400px;
  transform: translate(30px, -30px);
  cursor: pointer;
  transition: 0.3s ease;
  overflow-x: hidden;
  color: white;
  box-shadow: 0px 1px 5px 1px white;
}
.Stay_Display_Post > div > form > {
  text-align: end;
}
.Stay_Display_Post::-webkit-scrollbar {
  display: none;
}
.Stay_Display_Post > div > form > input,
.Stay_Display_Post > div > form > textarea {
  width: 390px;
  background-color: rgba(255, 255, 255, 0.119);
  padding: 5px;
  margin: 3px;
  outline: none;
  border-radius: 5px;
  color: white;
}

.Post_btns {
  color: #2b7bea;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  font-size: 25px;
  cursor: pointer;
  margin-left: 300px;
}
.Post_btns_msg {
  color: black;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  font-size: 25px;
  cursor: pointer;
}
.Post_btns_post {
  color: greenyellow;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  font-size: 25px;
  cursor: pointer;
}
.Stay_Hotel_Display_About {
  color: white;
  margin-left: 15px;
  text-align: start;
}
.Stay_Hotel_Display_Ratings {
  text-align: start;
  margin: 5px 0 5px 15px;
  color: white;
}
.RatingStar {
  color: gold;
}
.Stay_Hotel_Display_Reviews {
  color: white;
  padding-bottom: 280px;
}
.Stay_Hotel_Display_Reviews > div {
  text-align: start;
  margin-left: 15px;
}
.Stay_Hotel_Display_Review {
  color: white;
}
.Stay_Hotel_Display_Review > h4 {
  margin: 0;
}
.Stay_Hotel_Display_Review > p {
  margin: 0 0 10px 0;
}
.Stay_Hotel_Display_Review > div > span {
  color: gold;
  padding: 3px;
}

/* Date Show */
.hotel_date_card {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 61;
  background: rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  backdrop-filter: blur(15px);
  padding-left: 38%;
  padding-top: 70px;
  transition: 0.3s ease;
  color: white;
}
.hotel_date_card > div {
  width: 330px;
  background-color: #333;
  padding: 5px;
  border-radius: 5px;
}
.hotel_date_card > div > h2 {
  margin: 1px;
}
.hotel_date_card > h2 {
  margin: 0;
}
.hotel_date_card_cancel > svg {
  font-size: 30px;
  color: rgba(6, 132, 210, 0.88);
  cursor: pointer;
}
.hotel_date_card_cancel {
  padding-left: 70px;
}
.hotel_date_card > div > span {
  margin: 5px;
}
.react-datepicker {
  width: 100%;
  max-width: 500px; /* Adjust this max-width as needed */
  margin: 0 auto; /* Center the date picker */
}
.react-datepicker__current-month {
  margin: 0;
}
.react-datepicker__day--highlighted {
  background-color: #ffeb3b;
  color: #000;
}
.react-datepicker__day--highlighted {
  background-color: rgb(30, 255, 0);
}

.react-datepicker__day--selected {
  background-color: rgb(30, 255, 0);
  color: black;
}
/* Custom styles for the DatePicker days */
.react-datepicker__day {
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  text-align: center;
  margin: 0.2em;
} /* Adjust positioning of the days if needed */
.custom-datepicker-position .react-datepicker__month {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center dates */
}
.react-datepicker__day-names {
  display: flex;
  justify-content: space-evenly;
}
.hotel_date_card > h2 {
  color: white;
}
.Hotel_free {
  color: white;
}
.Hotel_booked {
  color: rgb(30, 255, 0);
}
.Hotel_Calander {
  padding-left: 5px;
  cursor: pointer;
}
.Hotel_Calander > span > svg {
  font-size: 20px;
  margin-left: 5px;
}
.Hotel_Calander > a {
  margin: 5px;
  color: white;
}
.Hotel_Share_Btn {
  margin-left: 5px;
  cursor: pointer;
  z-index: 59;
  font-size: 20px;
}
.Hotel_Share_Btn:hover {
  color: rgb(0, 116, 206);
  transition: 0.3s ease;
}

@keyframes loadanimation {
  0% {
    transform: rotate(0deg);
  }
  20% {
    background-color: blue;
  }
  40% {
    background-color: blueviolet;
  }
  60% {
    background-color: greenyellow;
  }
  80% {
    background-color: yellow;
  }
  100% {
    transform: rotate(360deg);
  }
}
.Places_contant_item_empty {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
}
@keyframes Loaderbtn {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

@media only screen and (max-width: 900px) {
  .Stay_contant {
    padding-top: 55px;
    padding-left: 10px;
  }
  .Stay_contant_title {
    font-size: 20px;
    padding: 8px;
    margin-left: 10px;
  }
  .Stay_hotel_img {
    height: 180px;
    width: 260px;
  }
  .Stay_data {
    margin-left: 20px;
  }
  .loading_section {
    padding-top: 50px;
  }
  .Stay_contant_Hotels {
    margin-left: 5px;
  }
  .Stay_Filter {
    left: -40px;
    height: 40px;
    border-radius: 5px 0 0 5px;
  }
  .card {
    top: 120px;
    left: 10px;
    right: 10px;
  }
  .Stay_Hotel_Display_Imgs {
    width: 100%;

    margin: 5px;
  }
  .Stay_Hotel_Details_Img {
    width: 370px;
  }
  .hotel_date_card {
    left: 0;
    padding-top: 100px;
    padding-left: 25%;
  }
}
@media only screen and (max-width: 480px) {
  .DisplayHotelCancel {
    right: -20px;
    top: 10px;
  }
  .Stay_Display_Post {
    width: 300px;
    margin-top: 15px;
    height: fit-content;
  }
  .Stay_Display_Post > div > form > input,
  .Stay_Display_Post > div > form > textarea {
    width: 290px;
  }
  .hotel_date_card {
    left: 0;
    padding-top: 150px;
    padding-left: 30px;
  }
  .Post_btns {
    margin-left: 250px;
  }
}
