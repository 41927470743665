.Contant_Titel {
  text-align: left;
  padding-left: 90px;
  color: white;
  border-radius: 5px 0 0 5px;
}

.Contant_Highlight {
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  gap: 20px;
  padding-bottom: 20px;
  margin-left: 110px;
  margin-right: 15px;
  border-radius: 5px;
}
.Contant_Highlight_Loading {
  margin-top: 30px;
  text-align: center;
}

.Contant_Highlight::-webkit-scrollbar {
  height: 8px;
  cursor: grabbing;
}

.Contant_Highlight::-webkit-scrollbar-track {
  background: transparent;
}

.Contant_Highlight::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.Contant_Highlight::-webkit-scrollbar-thumb:hover {
  background: rgba(172, 255, 47, 0.512);
}

.Contant_img:hover {
  transform: scale(1.1);
  transition: 0.5s;
  z-index: 5;
}
.Contant_img {
  height: 300px;
  width: 300px;
  border-radius: 80px;
  box-shadow: 5px 10px 10px;
  cursor: pointer;
}

.custome_image {
  border-radius: 5px;
}
.Contant_item_title {
  text-align: center;
  color: white;
  background-color: rgba(128, 128, 128, 0.797);
  border-radius: 10px;
  margin: unset;
}
.Video_contant_play {
  height: 300px;
  width: 400px;

  display: unset;
  z-index: 5;
  position: absolute;
  border: 5px solid gray;
}
.loading-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Contant_Higlights {
  height: 150px;
  width: 250px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 5px solid black;
  background-color: black;
  border-radius: 5px;
  margin-bottom: 5px;
}

.custom-video-player {
  border: 15px black;
  border-radius: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.custom-video-player:hover {
  z-index: 10;
}

@media only screen and (max-width: 900px) {
  .Contant_img {
    height: 200px;
    width: 200px;
  }

  .Contant_Highlight::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    display: none;
  }
  .Contant_Titel {
    padding-left: 10px;
    font-size: 25px;
  }
  .Contant_video_active {
    right: -150px;
    height: 30px;
    width: 30px;
    font-size: 20px;
  }
  .Video_contant_play {
    width: 400px;
    height: 400px;
  }
  .Contant_Highlight {
    gap: 5px;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 20px;
  }
}

@media only screen and (max-width: 450px) {
  .Contant_Highlight {
    margin-left: 15px;
  }

  .Contant_Highlight::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    display: none;
  }
  .Contant_Titel {
    padding-left: 15px;
  }
}
