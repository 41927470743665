.Terms_contant{
    color: white;
    background-color: #333;
    
    padding: 20px 20px 20px 90px;
}
.Terms_contant>p{
    text-align: start;
}
@media only screen and (max-width:900px){
    .Terms_contant{
        padding: 80px 5px 5px 10px;
    }
}
@media only screen and (max-width:480px){
    .Terms_contant{
        padding: 70px 5px 5px 10px;
    }
}