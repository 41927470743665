.AdminUser_Contant {
  min-height: 600px;
  color: white;
  background-color: black;
  padding-top: 10px;
  transition: 0.3s ease;
  position: relative;
  text-align: center;
}
.Data_Table {
  margin-left: 110px;
  margin-top: 15px;
  margin-bottom: 10px;
  overflow-x: hidden;
  border-radius: 10px;
  color: gray;
  transition: 0.5s ease;
  background-color: rgba(128, 128, 128, 0.109);
}
.Data_Table > thead > tr > th {
  width: fit-content;
  min-width: fit-content;
  border: 1px solid rgba(255, 255, 255, 0.464);
  border-radius: 5px;
  font-weight: 700;
}

.Data_Table > tbody > tr > td {
  height: 25px;
  width: fit-content;
  min-width: fit-content;
  border: 1px solid rgba(255, 255, 255, 0.464);
  border-radius: 5px;
  padding: 5px;
  color: white;
  cursor: text;
}
.Data_Table > tbody > tr:hover {
  background-color: rgba(128, 128, 128, 0.593);
}
.Data_Table > tbody > tr:hover {
  cursor: pointer;
  transition: 0.3s ease-in;
}
.Admin_Btn {
  cursor: pointer;
}
.Admin_Btn:hover {
  color: rgb(11, 149, 235);
}
.Admin_Form_Email {
  width: 300px;
  border: unset;
  border-radius: 5px 0 0 5px;
  padding: 10px;
}
.Admin_Form_Btn {
  cursor: pointer;
  border: unset;
  border-radius: 0 5px 5px 0;
  padding: 10px;
}
.Admin_Form_Excel_Btn {
  cursor: pointer;
  border: unset;
  border-radius: 5px;
  margin-bottom: 30px;
}
.Admin_Form_Btn:hover {
  background-color: rgb(11, 149, 235);
  color: white;
}
.Admin_Form_Excel_Btn:hover {
  background-color: rgb(11, 149, 235);
  color: white;
}
.Unauthuser_Contant {
  color: white;
  min-height: 600px;
  gap: 2px;
  padding-left: 50px;
  padding-top: 200px;
}
.Unauthuser_Contant_Icon {
  height: 30px;
  width: 30px;
  color: red;
}
.Filter_User_Empty {
  color: white;
}
.Unauthuser_Contant_Msg {
  margin-left: 10px;
  margin-right: 10px;
}
.Admin_Edit_Form {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 61;
  background: rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  backdrop-filter: blur(15px);
  padding-left: 38%;
  padding-top: 40px;
  transition: 0.3s ease;
  color: white;
  text-align: start;
}
.Admin_Edit_Title{
  padding: 0 10px 0 10px;
  margin: 2px;
  text-align: start;
}
.Admin_Edit_Form_Contant{
  margin-top: 5px;
  width: 300px;
  padding: 10px;
  border: 1px solid gray;
  border-radius: 10px;
}
.Admin_Edit_Form > form > div > input{
  width: 250px;
  border-radius: 5px;
  background-color: rgba(128, 128, 128, 0.335);
  color: white;
}
.Admin_Edit_Form > form > div > textarea{
  width: 280px;
  border-radius: 5px;
  background-color: rgba(128, 128, 128, 0.279);
  color: white;
}
.Admin_Edit_Form > form > div > input[type=text]:nth-child(14){
  margin-bottom: 5px;
}
.Admin_Edit_Form > form > div > label{
  display: block;
  width: 235px;
}
.Admin_Edit_Form_None {
  display: none;
}
.Admin_Edit_Form > form > button{
  border: unset;
  border-radius: 5px;
   margin: 5px;
   cursor: pointer;
   
}
.Admin_Edit_Form > form > button:hover{
  background-color: rgb(11, 149, 235);
  color: white;
  filter: drop-shadow(5px 0 10px rgb(11, 149, 235));
}
@media only screen and (max-width: 900px) {
  .AdminUser_Contant {
    padding-top: 70px;
  }
  .Data_Table {
    margin-left: 20px;
    margin-left: 20px;
  }
  .AdminUser_Contant_Titel {
    font-size: 20px;
  }
  .Unauthuser_Contant {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 180px;
  }
  .Unauthuser_Contant_Msg {
    margin-left: 10px;
    margin-right: 10px;
  }
  .Admin_Edit_Form {
    padding-left: 25%;
    padding-top: 80px;
  }
}
@media only screen and (max-width: 480px) {
  .Admin_Form_Email {
    width: 280px;
  }
  .AdminUser_Contant {
    padding-top: 60px;
  }
  .Admin_Edit_Form {
    padding-left: 30px;
    padding-top: 80px;
  }
  .Admin_Edit_Title{
    font-size: 17px;
  }
}
