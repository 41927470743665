.Timebar_contant {
  margin-top: 0px;
  background-color: #333333c6;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  align-content: center;
  padding-top: 50px;
  font-family: "Poppins", sans-serif;
}

.Timebar_time_1 {
  color: aliceblue;
  background-color: rgba(159, 157, 157, 0.697);
  padding: 10px;
  border-radius: 10px 0 0 10px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.Timebar_time_2 {
  color: aliceblue;
  background-color: rgba(159, 157, 157, 0.697);
  padding: 10px;
  border-radius: 0 10px 10px 0;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}
.Timebar_contant_2 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #333333c6;
}
.Timebar_time_3 {
  color: #fdfafa;
  background-color: #333333;
  border-radius: 10px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  filter: drop-shadow(0 0 2px green);
  margin: 5px 0 0 0;
}
.Timbar_Navigations {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;

  padding: 10px;
  border-radius: 10px;
  margin: 10px;
  margin-left: 90px;
}
.Timbar_Navigation > a {
  text-decoration: none;
}
.Timbar_Navigation > a > img {
  height: 100px;
  width: 100px;
  transition: 0.2s ease;
  cursor: pointer;
  padding: 5px;
  border-radius: 10px;
  background-color: white;
}
.Timbar_Navigation > a > svg {
  height: 100px;
  width: 100px;
  transition: 0.2s ease;
  cursor: pointer;
  padding: 5px;
  border-radius: 10px;
  background-color: white;
  color: black;
}
.Timbar_Navigation > a > p {
  margin: 1px;
  color: white;
  text-decoration: none;
}
.Timbar_Navigation > a > img:hover {
  background-color: #64e664;
  border-radius: 10px;
}
.Timbar_Navigation > a > svg:hover {
  background-color: #64e664;
  border-radius: 10px;
}
.Timbar_Navigation > img:hover {
  .Timbar_Navigation > p {
    color: white;
  }
}

@media only screen and (max-width: 900px) {
  .Timebar_contant {
    margin-top: 0px;
  }

  .Timebar_time_1 {
    padding: 5px;
    font-size: 10px;
  }

  .Timebar_time_2 {
    padding: 5px;
    font-size: 10px;
  }
  .Timebar_time_3 {
    font-size: 25px;
    padding: 5px;
  }
  .Timbar_Navigations {
    margin-left: 10px;
  }
  .Timbar_Navigation > a > img {
    height: 70px;
    width: 70px;
  }
  .Timbar_Navigation > a > svg {
    height: 70px;
    width: 70px;
  }
}
.Regi_time {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 480px) {
  .Timbar_Navigation > a > img {
    height: 50px;
    width: 50px;
  }
  .Timbar_Navigation > a > svg {
    height: 50px;
    width: 50px;
  }
}
