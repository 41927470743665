.Businessin_Conatnt {
  background-color: black;
  color: white;
  padding-top: 10px;
  padding-left: 110px;
  text-align: start;
  min-height: 600px;
}
.Businessin_Conatnt_Title {
  color: white;
  padding: 5px;
  background-image: linear-gradient(
    to right,
    rgba(128, 128, 128, 0.774),
    rgba(128, 128, 128, 0)
  );
  border-radius: 0 0 5px 5px;
  width: fit-content;
}
.Business_search_bar_item {
  position: relative;
}
.Business_contant_search {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Business_search_bar {
  width: 400px;
  border-radius: 5px;
  height: 40px;
}
.Business_call_Btn_1 {
  background-color: rgb(30, 255, 0);
  padding: 5px;
  border-radius: 5px;
  position: absolute;
  width: 30px;
  padding-bottom: 0;
  cursor: pointer;
  margin: 5px;
  transform: translate(80px, -40px);
}
.Business_call_Btn_2 {
    background-color: rgb(30, 255, 0);
    padding: 5px;
    border-radius: 5px;
    position: absolute;
    right: 50px;
    width: 40px;
    padding-bottom: 0;
    cursor: pointer;
    margin: 5px;
    height: 35px;
    transform: translate(20px, -30px);
    overflow: hidden;
    cursor: pointer;
    transition: 0.3s ease;
}
.Business_call_Btn {
  background-color: rgb(30, 255, 0);
  padding: 5px;
  border-radius: 5px;
  position: absolute;
  width: 30px;
  padding-bottom: 0;
  cursor: pointer;
  margin: 5px;
  transform: translate(180px, -40px);
}
@media only screen and (max-width: 900px) {
  .Businessin_Conatnt {
    padding-left: 30px;
    padding-top: 60px;
  }
  .Business_search_bar {
    width: 300px;
  }
}
@media only screen and (max-width: 480px) {
  .Businessin_Conatnt {
    padding-left: 20px;
  }
}
