.posto_contant {
  height: 700px;
  background-color: black;
  color: white;
  padding-top: 20px;
}
.posto_contant_title {
  margin: 0;
  padding: 10px;
  background-image: linear-gradient(to right, rgba(128, 128, 128, 0.774), rgba(128, 128, 128, 0));
  border-radius: 5px;
  width: fit-content;
  margin-left: 90px;
}
.posto_contant_items {
  height: 450px;
  width: 300px;
  background-color: gray;
  border-radius: 10px;
}
.posto_contant_item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 20px 20px 0 20px;
}
.posto_contant_Video {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 310px;
}
.posto_video {
  height: 400px;
}
.Posto_videos{
  width: 300px;
}
.posto_comment_sec {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  height: 50px;
  overflow-y: scroll;
  border: 1px solid black;
}
.posto_comments_send_sec > span > svg {
  height: 30px;
  width: 30px;
  padding-top: 5px;
  cursor: pointer;
  border-radius: 10px;
}
.posto_comments_send_sec > span > svg:hover {
    background-color: white;
    color: black;
    transition: 0.3s;

}
.posto_comments_send {
  width: 260px;
  background-color: rgba(128, 128, 128, 0);
  border: none;
  outline: none;
  color: white;
}
