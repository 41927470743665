.Signup_contant {
  /* margin-top: 100px; */
  padding-top: 20px;
  padding-bottom: 30px;
  width: 100%;
  min-height: 700px;
  height: fit-content;
  background-color: black;
  color: white;
  transition: all 0.5s ease;
}
.User_Profile_Section {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  padding-top: 20px;
  
}
.Signup_User_greet {
  padding-left: 90px;
  text-align: start;
}
.User_Profile_Section_item{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  padding-top: 20px;
  border: 1px solid hsl(190, 95%, 30%);
  padding: 50px 100px 50px 100px;
  border-radius: 10px;
  box-shadow: 5px 5px 10px hsl(190, 95%, 30%);
}

.User_Profile_Section_item > button {
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.Signup_Partners {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-around;
  padding: 20px;
}

.Signup_section {
  height: fit-content;
  min-width: 360px;
}
.Signin_btns:hover {
  cursor: pointer;
  background-color: hsl(190, 95%, 30%);
  filter: drop-shadow(0 0 20px hsl(190, 95%, 30%));
  transition: 0.5s;
  border: none;
  color: white;
  font-weight: 600;
}
.Terms_agree {
  position: absolute;
  background-color: hsl(190, 95%, 30%);
  top: 462px;
  width: 417px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

.Signin_btns {
  padding: 10px;
  background-color: hsl(190, 95%, 30%);
}

.Signin_btns_Partner_Link {
  border: none;
  border-radius: 5px;
  background-color: aliceblue;
}
.Terms_Link {
  font-size: 15px;
}
@media only screen and (max-width: 900px) {
  .Signup_contant {
    padding-top: 75px;
    padding-top: 50px;
    padding-bottom: 100px;
    min-height: 500px;
    height: fit-content;
  }
  .Signup_section {
    margin-top: 40px;
    padding-bottom: 30px;
  }
  .Signup_User_greet {
    padding-left: 30px;
    font-size: 18px;
  }
  .Terms_agree {
    top: 561px;
  }
}
@media only screen and (max-width: 480px) {
  .Terms_agree {
    top: 561px;
    width: 296px;
    height: 43px;
  }
}
