.Business_Contant {
  min-height: 600px;
  color: white;
  margin-left: 110px;
  transition: all 0.5s ease;
}
.hover-container {
  position: relative;
  height: 500px;
  overflow: hidden;
  background-image: url("https://t3.ftcdn.net/jpg/02/88/80/38/360_F_288803822_0CJ8L3gr6w6nGnUeje6pCllCX7s986xz.jpg");
  padding: 15px;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-blend-mode: darken;
  width: auto;
  overflow: hidden;
  cursor: url("../assets/images/mouse.png"), auto;
}

.star {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: rgba(6, 132, 210, 0.447);
  border-radius: 50%;
  animation: sparkle 1s linear;
}

@keyframes sparkle {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  50% {
    transform: scale(2);
    opacity: 0.5;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}
.Business_Contant_Tagline {
  padding: 5px;
  border-radius: 10px;
  font-size: 800;
  width: fit-content;
  text-align: center;
  cursor: default;
}
.hover-container:hover .mouse-animation {
  transform: scale(1); /* Show the animation on hover */
}
.Business_Contant > h1 {
  background-image: linear-gradient(
    to right,
    rgba(128, 128, 128, 0.774),
    rgba(128, 128, 128, 0)
  );
  border-radius: 0 0 5px 5px;
  padding: 5px;
  text-align: left;
}
.Business_About {
  text-align: start;
  margin-right: 10px;
  font-size: 20px;
  padding: 5px;
  background-color: rgba(65, 65, 67, 0.483);
  border-radius: 10px;
  width: fit-content;
  font-weight: 700;
  cursor: default;
}
.Business_Tools {
  padding: 10px;
  border-radius: 10px;
  margin-right: 20px;
  margin-top: 20px;
  background-color: black;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  gap: 15px;
}
.Business_Tools > img {
  height: 120px;
  width: auto;
  margin: 0 20px 0 20px;
}
.Business_Service {
  color: white;
  padding: 10px;
  padding-bottom: 30px;
  text-align: start;
  border-top: 3px solid blue;
  border-right: 3px solid blue;
  border-radius: 10px;
  width: fit-content;
}
.Business_Services_Name {
  font-size: 35px;
}
.Google_Cloud {
  height: 120px;
  width: 120px;
}
.svg_icon {
  text-align: center;
}
.BDS_Pricing {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-top: 15px;
  margin: 20px;
  position: relative;
}
.BDS_Pricing > p {
  font-size: 50px;
}
.BDS_Pricing_Img {
  border-radius: 30px;
  filter: drop-shadow(5px 0px 50px rgb(139, 217, 56));
}

.BDS_Pricing_Ani {
  position: absolute;
  height: 300px;
  width: 300px;
  background-color: red;
  z-index: -1;
  right: 235px;
  bottom: 10px;
  border-radius: 50px;
  filter: drop-shadow(5px 0px 50px red);
}
.BDS_Chat {
  padding: 100px 0 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.App_Chat_Btn_aro {
  transform: rotate(50deg);
  transition: 0.5s ease-in;
  color: greenyellow;
}
.App_Chat_Btn_aro > svg {
  font-size: 40px;
}
.App_Chat_Btn_Aro_Nor {
  margin-left: 10px;
}
.BDS_Client {
  margin: 10px;
  padding-bottom: 20px;
}
.BDS_Client_Title {
  font-size: 40px;
}
.BDS_Clients {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.BDS_Clients_Logo {
  height: 100px;
  width: auto;
  border-radius: 20px;
}
.BDS_Clients_Details {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid gray;
  gap: 5px;
  color: white;
  min-width: 180px;
}
.BDS_Clients_Link {
  color: white;
  text-decoration: unset;
  cursor: pointer;
}
@media only screen and (max-width: 900px) {
  .Business_Contant {
    padding-top: 70px;
    margin-left: 30px;
  }
  .Business_Contant > h1 {
    font-size: 30px;
    text-align: left;
    margin-left: 20px;
  }
  .Business_Tools > img {
    height: 80px;
    width: auto;
    margin: 5px;
  }
  .Business_Contant > h1 {
    font-size: 20px;
  }
  .hover-container {
    height: fit-content;
  }
}
@media only screen and (max-width: 480px) {
  .Business_Contant {
    padding-top: 60px;
    margin-left: 10px;
  }
  .Business_Contant > h1 {
    font-size: 20px;
    padding: 8px;
  }
  .Business_Contant_Tagline {
    font-size: 15px;
    margin: 0;
  }
  .Business_About {
    width: 350px;
    height: fit-content;
  }
  .Business_Contant > h1 {
    font-size: 17px;
    margin-left: 10px;
  }
  .hover-container {
    height: fit-content;
    width: 390px;
  }
  .Business_Services_Name {
    font-size: 25px;
  }
  .Business_Service {
    padding-left: 25px;
  }
  .BDS_Pricing_Ani {
    right: 150px;
    bottom: 10px;
    height: 200px;
    width: 200px;
  }
  .BDS_Pricing > P {
    font-size: 30px;
  }
  .BDS_Pricing_Img {
    height: 200px;
  }
  .BDS_Chat {
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 25px;
  }
}
