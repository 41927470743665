.Task_Contant {
  width: 100%;
  padding-top: 10px;
  min-height: 600px;
  max-height: fit-content;
  background-color: black;
  font-family: "Poppins", sans-serif;
  padding-left: 40px;
  color: white;
  transition: all 0.5s ease;
}
.Task_Contant_Title {
  padding-bottom: 10px;
}
.Plan-1 {
  width: 100%;
  position: relative;
  padding-left: 80px;
  padding-bottom: 20px;
}
.Plan_1_Title {
  position: absolute;
  top: 20px;
  left: 90px;
  padding: 10px;
  /* background-color: rgba(128, 128, 128, 0.774); */
  background-image: linear-gradient(
    to right,
    rgba(128, 128, 128, 0.774),
    rgba(128, 128, 128, 0)
  );
  border-radius: 0 0 0 5px;
}
.Plan_1_Video {
  width: 100%;
  display: block;
  opacity: 0;
  height: 80px;
  border-radius: 20px;
  transition: 0.5s ease-in-out;
}
.visible-video {
  opacity: 1;
  height: 500px;
  margin-bottom: 10px;
  border-radius: 20px;
  transition: 0.5s ease-out;
}
.Task_table {
  text-align: start;
  padding-left: 100px;
  max-width: 100%;
  transition: 0.5s linear;
}
.Task_table_scroll {
  color: black;
  transition: 0.3s linear;
  text-align: start;
}

@media only screen and (max-width: 900px) {
  .Task_Contant {
    padding-top: 55px;
    padding-left: 0;
  }
  .visible-video {
    height: 400px;
    width: 100%;
    border-radius: 10px;
  }
  .Plan_1_Title {
    top: 10px;
    left: 25px;
    
  }
  .Plan-1 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .Task_table {
    padding-left: 20px;
  }
}
@media only screen and (max-width: 480px) {
  .visible-video {
    height: 220px;
    width: 100%;
  }
}
