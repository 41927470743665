.About_contant {
  padding: 20px 20px 20px 50px;
  color: white;
  background-color: #333;
}
.About_Team_member {
  max-width: 600px;
  border: 2px solid rgba(160, 157, 157, 0.661);
  margin: 5px;
  padding: 25px;
  border-radius: 10px;
}
.About_ootygo {
  padding: 10px;
  border: 2px solid black;
  border-radius: 5px;
  margin-top: 30px;
  margin-left: 50px;
}
.About_OurTeam {
  margin-left: 50px;
}
.About_Team_member > img,
h2 {
  align-items: center;
  align-content: center;
  margin: 2px;
  padding-left: 10px;
}
.About_img {
  width: 200px;
  height: 200px;
  border-radius: 100px;
  position: relative;
}
.About_team_members {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
}
.About_team_title {
  width: fit-content;
  padding: 5px;
  border-radius: 10px;
}
.About_ootygo > p {
  text-indent: 20px;
}
.About_tec_icon {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 10px;
}
.About_tec_icon > span > svg {
  height: 40px;
  width: 45px;
}
.About_tec {
  padding: 10px;
  margin-bottom: 5px;
  margin-left: 55px;
  border: 2px solid black;
  border-radius: 5px;
}
.About_career {
  padding: 10px;
  margin-bottom: 5px;
  margin-left: 55px;
  border: 2px solid black;
  border-radius: 5px;
  text-align: start;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
}
.About_career_Items {
  width: 480px;
}
.About_career > h3 {
  text-align: center;
}
@media only screen and (max-width: 900px) {
  .About_ootygo {
    margin-left: 10px;
    margin-top: 65px;
  }
  .About_OurTeam {
    margin-left: 10px;
  }
  .About_career {
    margin-left: 10px;
  }
  .About_contant {
    padding-left: 10px;
  }
  .About_tec {
    margin-left: 15px;
    margin-right: 5px;
  }
}
