.Places_contant {
  background-color: rgb(0, 0, 0);
  height: fit-content;
  transition: all 0.5s ease;
  width: 100%;
  padding-left: 50px;
  padding-top: 50px;
  font-family: "Poppins", sans-serif;
  position: relative;
}

.Places_contant_titel {
  color: white;
  position: absolute;
  z-index: +1;
  top: 0;
  left: 150px;
  background-image: linear-gradient(
    to right,
    rgba(128, 128, 128, 0.774),
    rgba(128, 128, 128, 0)
  );
  padding: 8px;
  border-radius: 0 0 5px 5px;
}
.Places_contant_item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  margin: 50px 0 0 40px;
}
.Places_contant_item_empty {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
}
.Places_contant_title {
  color: white;
}
.Places_contant_sub {
  color: white;
  align-items: center;
  text-align: center;
}
.Places_contant_about {
  color: white;
}
.Place_contant_place {
  position: relative;
  min-width: 200px;
}
.Places_contant_counts {
  position: absolute;
  bottom: -25px;
  left: -15px;
  /* color: rgba(255, 255, 255, 0.684); */
  color: chartreuse;
  font-size: 90px;
  filter: drop-shadow(7px 5px 5px rgba(0, 0, 0, 0.8));
  cursor: pointer;
}

@media only screen and (max-width: 900px) {
  .Places_contant {
    padding-top: 65px;
    padding-left: 10px;
  }
  .Places_contant_titel {
    left: 20px;
    font-size: 20px;
    top: 60px;
  }
  .Places_contant_item {
    margin-top: 70px;
  }
  .Places_contant_item {
    margin-left: 10px;
  }
}
.Place_contant_img {
  cursor: pointer;
  height: 200px;
  width: 250px;
  border-radius: 10px;
}
.Place_contant_img:hover {
  transform: scale(1.1);
  transition: 150ms;
}
.Place_contant_img.Places_contant_counts:hover {
  color: chartreuse;
  transition: 0.5s;
}
