.Home_body {
  background-color: #333;
  transition: all 0.5s ease;
  z-index: -3;
  font-family: "Poppins", sans-serif;
  width: 100%;
  scroll-behavior: smooth;
}
@media (max-width: 480px) {
  .Home_body {
    background-size: 100% 100%;
  }
}
.Home_video_container {
  position: relative;
  background-color: #333333c6;
}
.Home_video_titel {
  position: absolute;
  color: white;
  top: 30%;
  left: 10%;
  font-size: 50px;
  
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.806),rgba(0, 0, 0, 0));
  padding: 10px;
  border-radius: 10px;
  transition: all 0.3s ease-out;
}
.Home_video {
  background-color: black;
  height: -webkit-fill-available;
  object-fit: cover;
}

@media only screen and (max-width: 900px) {
  .Home_video {
    height: 750px;
  }
  .Home_video_titel {
    font-size: 20px;
    top: 35%;
    left: 25%;
  }
}


