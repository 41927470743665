.Travel_contant {
  /* margin-top: 95px; */
  width: 100%;
  padding-top: 10px;
  min-height: 600px;
  max-height: fit-content;
  background-color: black;
  font-family: "Poppins", sans-serif;
  padding-left: 40px;
}
.Travel_contant_Vehicle {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 15px;
  flex-wrap: wrap;
  padding-bottom: 30px;
}
.Places_contant_item_empty {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
}
.Travel_contant_Vehicles {
  height: 320px;
  width: 320px;
  background-color: rgb(211, 208, 208);
  color: black;
  padding: 9px;
  border-radius: 5px;
  box-shadow: 2px 5px 5px gray;
}
.Travel_Car {
  margin: 0;
}
.Travel_contant_title {
  color: white;
  padding: 5px;
  background-image: linear-gradient(
    to right,
    rgba(128, 128, 128, 0.774),
    rgba(128, 128, 128, 0)
  );
  border-radius: 0 0 5px 5px;
  width: fit-content;
  margin-left: 90px;
}
.Travel_contant_search {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
  margin-left: 50px;
}
.Travel_Vehicle_title {
  margin: 0;
}
.Travel_contant_search_title {
  color: white;
}
.Travel_contant_location {
  outline: none;
  padding: 10px;
  width: 300px;
  font-size: 20px;
  font-weight: 500;
  border-radius: 5px;
  transition: 0.5s;
}
.Travel_sitting_icon{
  height: 25px;
  width: 25px;
}
.Travel_cotant_date {
  outline: none;
  padding: 10px;
  width: 300px;
  font-size: 20px;
  font-weight: 700;
  border-radius: 5px;
}
.Tourist_Count{
  position: relative;
  top: -6px;
  padding-left: 2px;
  margin-right: 10px;
}
.Travel_contant_search_btns {
  display: flex;
  justify-content: center;
  margin: 30px;
}
.Travel_contant_search_btn {
  background-color: rgb(19, 146, 200);
  padding: 10px;
  font-size: 30px;
  outline: none;
  border-radius: 10px;
  width: 180px;
  color: white;
  cursor: pointer;
}
.Travel_contant_search_btn:hover {
  transform: scale(1.1);
  transition: 150ms;
}
.Travel_vehicles_title {
  color: white;
  margin: 0;
}
.Travel_vehicles {
  padding-left: 60px;
}
.Travel_vehicle_items {
  color: white;
  display: flex;
  justify-content: center;
  justify-items: center;
  flex-wrap: wrap;
  gap: 10px;
  text-align: center;
  padding-bottom: 20px;
}
.Travel_vehicle_item > img {
  border-radius: 5px;
  cursor: pointer;
}

.Travel_vehicle_item > h3 > span > svg {
  color: rgba(0, 0, 255, 0.854);
  margin-left: 5px;
}
.Travel_call_btn {
  background-color: rgb(30, 255, 0);
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  width: 40px;
  position: absolute;
  cursor: pointer;
  margin: 5px;
  transform: translate(60px, -25px);
}
.Travel_call_btn > svg {
  color: rgb(0, 0, 0);
}
.Travel_call_btn:hover {
  background-color: rgba(6, 132, 210, 0.88);
  filter: drop-shadow(0 0 15px rgba(6, 132, 210, 0.88));
  transition: 0.3s;
  color: rgb(255, 255, 255);
  font-weight: 500;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  background-color: black;
}

.loading-spinner {
  border: 5px solid #078bc9;
  border-top: 5px solid black;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Travel_Search_Title {
  color: white;
  margin: 2px;
}
.Travel_Search_Result {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.Travel_sitting_capacity {
  color: black;
  text-align: center;
  position: relative;
}

.Travel_sitting_capacity_items {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Travel_Search_Btn {
  background-color: #078bc9;
  border: none;
  padding: 2px;
  width: 30px;
  position: absolute;
  right: -1px;
  cursor: pointer;
  height: 40px;
  border-radius: 0 5px 5px 0;
  width: 40px;
}
.Search_cancel {
  cursor: pointer;
  background-color: #078bc9;
  border: none;
  padding: 5px 8px 5px 8px;

  border-radius: 10px;
}
.Travel_Search_Btn:hover.Travel_Search_Btn > svg {
  color: white;
}

.Vehicle_Search_0 {
  color: white;
}
.Travel_search_bar_item {
  position: relative;
}
.Travel_search_bar {
  width: 400px;
  border-radius: 5px;
  height: 40px;
}

.Vehicle_Search_0 > img {
  margin: 5px;
  margin-left: -30px;
  border-radius: 5px;
  filter: drop-shadow(0 0 5px white);
}
/* Travel More info */

.Travel_Info {
  display: unset;
}
.Travel_Info_none {
  display: none;
}
.Travel_Vehicle_Filter {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  padding: 10px;
 
  flex-wrap: wrap;
}
.Travel_Vehicle_Filters {
  background-color: black;
  border: 2px solid rgb(106, 106, 214);
  border-radius: 10px;
  color: white;
  font-size: 10px;
  cursor: pointer;
  transition: 0.3s;
 
  
}
.Travel_Vehicle_Filters:hover {
  background-color: rgb(106, 106, 214);
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 900px) {
  .Travel_contant {
    padding-top: 55px;
    padding-left: 0;
  }
  .Travel_contant_title {
    font-size: 20px;
    padding: 8px;
    margin-left: 10px;
  }
  .Travel_contant_Vehicles {
    width: 280px;
    
  }
  .Travel_contant_search_btn {
    padding: 5px;
    font-size: 20px;
    margin: 10px;
    border: none;
  }
  .Travel_contant_search_btns {
    margin: 0;
  }
  .Travel_contant_location {
    padding: 7px;
    width: 250px;
    font-size: 15px;
    font-weight: 600;
  }
  .Travel_contant_date {
    padding: 7px;
    width: 250px;
    font-size: 15px;
    font-weight: 600;
  }
  .Travel_contant_search {
    margin-left: 40px;
  }
  .Travel_contant_search_btn {
    padding: 5px;
    font-size: 10px;
    width: 100px;
  }
  .Travel_vehicles {
    margin-left: 10px;
    padding: 10px;
  }
  .Travel_vehicle_item > img {
    height: 150px;
    width: 250px;
  }
  .Travel_vehicle_item > h3 {
    font-size: 20px;
  }
  .Travel_call_btn {
    transform: translate(50px, -20px);
  }
  .Travel_search_bar {
    width: 280px;
    border-radius: 0 5px 5px 0;
    border: none;
    outline: unset;
  }
  .Travel_Search_Result {
    padding-left: 10px;
  }
}
