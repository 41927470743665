.Support_contant{
    background-color: black;
    color: white;
    padding-top: 30px;
    padding-left: 110px;
    padding-bottom: 50px;
    height: 600px;
   
    text-align: start;
}
.Support_contant>h4>button{
    cursor: pointer;
    margin-left: 5px;
}
.Support_titel{
    background-image: linear-gradient(to right, rgba(128, 128, 128, 0.774), rgba(128, 128, 128, 0));
    padding: 5px;
    border-radius: 0 0 0 5px;
}
@media only screen and (max-width: 900px) {
    .Support_contant{
        padding-left: 30px;
        padding-top: 60px;
    }
}
