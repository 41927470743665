.Navbar {
  width: 80px;
  height: 100%;
  padding: 1rem 2rem;
  box-shadow: 0 2px 4px #0000001a;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 70;
  background-color: #333;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  flex-basis: content;
}

.Navbar.scrolled {
  height: 60px;
  background-color: unset;
  filter: drop-shadow(1px 1px 2px black);
  box-shadow: unset;
}

.Navbar_menu_item {
  background-color: rgba(6, 132, 210, 0.88);
  font-size: 15px;
  margin-top: 20px;
  height: fit-content;
  padding: 5px;
  border-radius: 50px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
  width: 70px;
  transition: 0.5s ease;
}
.Navbar_menu_item > svg {
  color: white;
}
.Business_Support {
  color: white;
  font-weight: 900;
  font-size: 20px;
}
.SignedIn_User_Icon{
  text-decoration: unset;
  
}
.Navbar_menu2_logo {
  height: 70px;
  width: 70px;

  filter: drop-shadow(2px 4px 6px black);
  cursor: pointer;
}
.Navbar_menu2_logo:hover {
  transition: 0.3s;
  transform: scale(1.2);
}
.Navbar_menu2 > :first-child {
  margin-top: 0;
}

.Navbar_menu_item:hover {
  background-color: rgb(27, 249, 175);
  filter: drop-shadow(0 0 10px rgb(27, 249, 175));
  cursor: pointer;
  color: black;
  transition-duration: 600ms;
}
.ib {
  width: 10px;
}
.Navbar_flag {
  height: 50px;
  margin-left: 10px;
}
.BDS {
  color: white;
}
.SignedIn_User_Name {
  border: 2px solid rgb(255, 255, 255);
  padding: 3px;
  border-radius: 50px;
  padding-left: 8px;
  padding-right: 8px;
  font-weight: 800;
  text-decoration: unset;
  text-underline-offset: unset;
}
.SignedIn_User_Name:hover {
  border: 2px solid black;
}
@media only screen and (max-width: 900px) {
  .Navbar_menu_item {
    font-size: 10px;
    margin-top: 10px;
    height: 35px;
    padding: 5px;
    border-radius: 50px;
    width: fit-content;
  }
  .Navbar_menu_item > svg {
    height: 25px;
    width: 25px;
  }
  .Navbar_flag {
    margin: 10px 0 0 0;
    height: 40px;
  }
  .Navbar_menu2_logo {
    height: 50px;
    width: 50px;
    border-radius: 100px;
    transition: all 0.3 ease;
  }
  .Navbar {
    height: 80px;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    box-shadow: none;
  }
  .bi {
    height: 15px;
    width: 20px;
  }
}
.Register_page {
  color: rgb(140, 15, 202);
  display: flex;
  align-items: center;
}

.Register_btn {
  color: #c90000;
  z-index: 3;
  background-color: rgba(20, 213, 152, 0.584);
}

#Signup_btn {
  text-decoration: none;
  color: azure;
}
#Home_btn {
  text-decoration: none;
  color: azure;
}
#Places_btn {
  text-decoration: none;
  color: azure;
}
.bi-house:hover {
  color: black;
}
.bi-person-circle:hover {
  color: black;
}
#Places_btn:hover {
  color: black;
}
